<template lang="html">
  <div>
    <h2>More Specific Technical Abilities</h2>
    <ul>
      <li>
        Automated QA testing with Cucumber, Cypress, Jest, Karma, Nightwatch, Mocha, Chai and Supertest
      </li>
      <li>
        Project management and team lead
      </li>
      <li>
        UI design and development
      </li>
      <li>
        DevOps automation with Docker and Kubernetes on Azure, Google Cloud, AWS and Heroku
      </li>
      <li>
        Designing linguistic and code style guides
      </li>
      <li>
        Building user interface sketches, storyboards and interactive prototypes
      </li>
      <li>
        Responsive and adaptive design for multiple devices and pixel densities
      </li>
      <li>
        Building and implementing APIs
      </li>
      <li>
        Security and regulatory compliance
      </li>
      <li>
        Developing a project's information architecture and content strategy
      </li>
      <li>
        Collecting and creating copy, content, micro-copy and brand person
      </li>
      <li>
        Page speed optimization by design and run-time software
      </li>
      <li>
        High fidelity mockups using Adobe Photoshop, Indesign, and Affinity Designer
      </li>
      <li>
        Creating brand identities and design assets
      </li>
    </ul>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
  ul > li {
    max-width:40rem;
    margin-bottom:1rem;
  }
</style>
